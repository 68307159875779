import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SLS_USER_ENDPOINTS } from '../../constants/apiEndpoints';
import { getSlsAxiosInstnce } from '../../hooks/useSls';
import { Reward, EduReward } from '../../types/Reward';
import { AppDispatch, RootState } from '../store';

interface Thunk3rdArg {
  dispatch: AppDispatch;
  state: RootState;
}

interface FetchRewardProp {
  rewards?: Reward[] | null;
  eduRewards?: EduReward[] | null;
}
export const fetchRewards = createAsyncThunk<FetchRewardProp, boolean, Thunk3rdArg>(
  SLS_USER_ENDPOINTS.GET_REWARDS,
  async (forceRefresh: boolean, { dispatch, getState }) => {
    const { user, rewards } = getState();
    dispatch(showLoader());
    if (!forceRefresh) {
      return {
        rewards: rewards.Rewards,
        eduRewards: rewards.EduRewards,
      };
    }

    const slsAxios = getSlsAxiosInstnce(dispatch, user.token);
    const response = await slsAxios.get<{ rewards: Reward[]; eduRewards: EduReward[] }>(
      SLS_USER_ENDPOINTS.GET_REWARDS,
    );
    return {
      rewards: response.data.rewards,
      eduRewards: response.data.eduRewards,
    };
  },
);

export interface RewardsState {
  Rewards: Reward[] | null | undefined;
  EduRewards: EduReward[] | null | undefined;
  isLoading: boolean;
}

const initialState: RewardsState = {
  Rewards: null,
  isLoading: true,
  EduRewards: null,
};

export const rewardsSlice = createSlice({
  name: 'rewards',
  initialState,
  reducers: {
    reset: (state) => {
      state.Rewards = null;
    },
    showLoader: (state) => {
      state.isLoading = true;
    },
    hideLoader: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchRewards.fulfilled, (state, action) => {
      state.Rewards = action.payload.rewards;
      state.EduRewards = action.payload.eduRewards;
      state.isLoading = false;
    });
    builder.addCase(fetchRewards.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

const { showLoader, hideLoader } = rewardsSlice.actions;

export const { reset } = rewardsSlice.actions;
export default rewardsSlice.reducer;
