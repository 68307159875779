import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SLS_USER_ENDPOINTS } from '../../constants/apiEndpoints';
import { getSlsAxiosInstnce } from '../../hooks/useSls';
import Badge from '../../types/Badge';
import { AppDispatch, RootState } from '../store';

interface Thunk3rdArg {
  dispatch: AppDispatch;
  state: RootState;
}
export const fetchSkills = createAsyncThunk<Badge[], boolean, Thunk3rdArg>(
  'fetchSkills',
  async (forceRefresh: boolean, { dispatch, getState }) => {
    const { user, skills } = getState();
    dispatch(showLoader());
    if (!forceRefresh) {
      if (skills.Skills?.length) {
        return skills.Skills;
      }
    }
    const slsAxios = getSlsAxiosInstnce(dispatch, user.token);
    const response = await slsAxios.get<{ items: Badge[] }>(SLS_USER_ENDPOINTS.GET_BADGES);
    return response.data.items;
  },
);

export interface SkilsState {
  Skills: Badge[] | undefined | null;
  isLoading: boolean;
}

const initialState: SkilsState = {
  Skills: null,
  isLoading: true,
};

export const skillsSlice = createSlice({
  name: 'skills',
  initialState,
  reducers: {
    reset: (state) => {
      state.Skills = null;
    },
    showLoader: (state) => {
      state.isLoading = true;
    },
    hideLoader: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchSkills.fulfilled, (state, action) => {
      state.Skills = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchSkills.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

const { showLoader, hideLoader } = skillsSlice.actions;

export const { reset } = skillsSlice.actions;
export default skillsSlice.reducer;
