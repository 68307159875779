import * as S from './styled';

interface LoaderProps {
  height?: string;
}
export const Loader = ({ height = '400px' }: LoaderProps) => {
  return (
    <S.Loader style={{ height: height }}>
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </S.Loader>
  );
};

export const FullScreenLoader = ({ height = '400px' }: LoaderProps) => {
  return (
    <S.Wrapper>
      <S.Loader style={{ height: height }}>
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </S.Loader>
    </S.Wrapper>
  );
};
