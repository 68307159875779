import axios, { AxiosInstance } from 'axios';
import { API_URL } from '../constants/apiEndpoints';
import { setNewJWTToken } from '../constants/customHttpHeaders';
import { logIn, logOut } from '../redux/slices/userSlice';
import { Dispatch } from 'react';
import { AnyAction } from '@reduxjs/toolkit';
import { selectToken } from '../redux/selectors/userSelector';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../redux/store';

export function useAxios() {
  const dispatch = useDispatch<AppDispatch>();
  const token = useSelector(selectToken);

  return getAxiosInstnce(dispatch, token);
}

function getAxiosInstnce(dispatch: Dispatch<AnyAction>, token: string | null): AxiosInstance {
  const instance = axios.create({
    baseURL: `${API_URL}`,
  });

  instance.interceptors.request.use(
    async (config) => {
      if (!token) {
        throw new axios.Cancel('Token missing');
      }
      config.headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
      return config;
    },
    (error) => {
      Promise.reject(error);
    },
  );

  instance.interceptors.response.use(
    async (response) => {
      if (response.headers[setNewJWTToken]) {
        const newToken = response.headers[setNewJWTToken];
        //@ts-ignore
        await dispatch(logIn(newToken));
      }
      return response;
    },
    async (error) => {
      if (error.response.status === 401) {
        //@ts-ignore
        await dispatch(logOut());
        throw new axios.Cancel('Unauthorized');
      }
    },
  );
  return instance;
}

export default getAxiosInstnce;
