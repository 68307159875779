import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { QUESTS, SLS_USER_ENDPOINTS } from '../../constants/apiEndpoints';
import getAxiosInstnce from '../../hooks/useAxios';
import { getSlsAxiosInstnce } from '../../hooks/useSls';
import Quest from '../../types/Quest';
import { AppDispatch, RootState } from '../store';
import { openModal } from './fullscreenModalSlice';
import { fetchRewards } from './rewardsSlice';
import { fetchSkills } from './skilsSlice';

interface Thunk3rdArg {
  dispatch: AppDispatch;
  state: RootState;
}
export const fetchQuests = createAsyncThunk<Quest[], boolean, Thunk3rdArg>(
  SLS_USER_ENDPOINTS.GET_QUESTS,
  async (forceRefresh: boolean, { dispatch, getState }) => {
    const { user, quests } = getState();
    if (!forceRefresh) {
      if (quests.Quests?.length) {
        return quests.Quests;
      }
    }
    const slsAxios = getSlsAxiosInstnce(dispatch, user.token);
    const response = await slsAxios.get<{ items: Quest[] }>(SLS_USER_ENDPOINTS.GET_QUESTS);
    return response.data.items;
  },
);

export const checkQuestByTextId = createAsyncThunk<void, string, Thunk3rdArg>(
  QUESTS.CHECKQUEST(''),
  async (textId: string, { dispatch, getState }) => {
    const { user } = getState();
    dispatch(showLoader());
    const axios = getAxiosInstnce(dispatch, user.token);
    const response = await axios.post(QUESTS.CHECKQUEST(textId));
    await dispatch(fetchQuests(true));
    if (response.data == null) {
      return;
    }

    if (response.data.message) {
      dispatch(
        openModal({
          text: response.data.message,
          isSuccess: response.data.passed,
          badgeUrl: response.data.badgeUrl,
          header: response.data.header,
        }),
      );
    }

    if (response.data.passed) {
      await dispatch(fetchSkills(true));
      await dispatch(fetchRewards(true));
    }
  },
);

export interface QuestsState {
  Quests: Quest[] | undefined | null;
  isLoading: boolean;
}

const initialState: QuestsState = {
  Quests: null,
  isLoading: true,
};
export const questsSlice = createSlice({
  name: 'quests',
  initialState,
  reducers: {
    reset: (state) => {
      state.Quests = null;
    },
    showLoader: (state) => {
      state.isLoading = true;
    },
    hideLoader: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchQuests.fulfilled, (state, action) => {
      state.Quests = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchQuests.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

const { showLoader, hideLoader } = questsSlice.actions;
export const { reset } = questsSlice.actions;

export default questsSlice.reducer;
