import { useEffect, useState } from 'react';
import { useAccount, useDisconnect } from 'wagmi';
import { SiweMessage } from 'siwe';
import { Signer } from 'ethers';
import { SIWE_MESSAGE_TO_SIGN } from '../../constants';

const createSiweMessage = (
  address: string | undefined,
  statement: string | undefined,
  domain: string | undefined,
  uri: string | undefined,
) => {
  const message = new SiweMessage({
    domain,
    address,
    statement,
    uri,
    version: '1',
    chainId: 1,
  });
  return message.prepareMessage();
};

const signInWithEthereum = async (signer: Signer, domain: string, origin: string) => {
  try {
    const address = await signer.getAddress();
    const message = createSiweMessage(address, SIWE_MESSAGE_TO_SIGN, domain, origin);

    const signature = await signer.signMessage(message);

    return { signature, message, isAuthorized: true };
  } catch (error) {
    return { signature: null, message: null, isAuthorized: false };
  }
};

export type SiweAuthOptions = {
  domain: string;
  origin: string;
  onSuccessfulSign?: (
    signature: string,
    message: string,
    isReconnected: boolean,
  ) => Promise<void> | void;
  onNotSuccessfulSignBeforeDisconnect?: () => Promise<void> | void;
  onDisconnect?: () => Promise<void> | void;
  onAccountChange?: () => Promise<void> | void;
  shouldResignSiweOnReconnect?: () => Promise<boolean> | boolean;
};

export const useSiweAuth = (options: SiweAuthOptions) => {
  const { disconnect } = useDisconnect();
  const [signedInWithAddress, setSignedInWithAddress] = useState<string | undefined>(undefined);

  const { address, isConnected } = useAccount({
    async onConnect({ address, connector, isReconnected }) {
      if (address && connector) {
        if (isReconnected && options.shouldResignSiweOnReconnect) {
          const shouldSignSiweAgain = await options.shouldResignSiweOnReconnect();
          setSignedInWithAddress(address);

          if (!shouldSignSiweAgain) {
            return;
          }
        }

        const signer = await connector.getSigner();
        const { signature, message, isAuthorized } = await signInWithEthereum(
          signer,
          options.domain,
          options.origin,
        );

        if (!isAuthorized) {
          if (options?.onNotSuccessfulSignBeforeDisconnect) {
            await options.onNotSuccessfulSignBeforeDisconnect();
          }
          disconnect();
        }

        if (signature) {
          if (options?.onSuccessfulSign) {
            setSignedInWithAddress(address);
            await options.onSuccessfulSign(signature, message, isReconnected);
          }
        }
      }
    },
    async onDisconnect() {
      setSignedInWithAddress(undefined);

      if (options?.onDisconnect) {
        await options.onDisconnect();
      }
    },
  });

  useEffect(() => {
    (async () => {
      if (isConnected && signedInWithAddress && address !== signedInWithAddress) {
        disconnect();
        if (options?.onAccountChange) {
          await options.onAccountChange();
        }
      }
    })();
  }, [address, disconnect, isConnected, options, signedInWithAddress]);

  return signedInWithAddress ?? null;
};

export default useSiweAuth;
