import { connectorsForWallets, wallet } from '@rainbow-me/rainbowkit';
import { chain, configureChains, createClient, WagmiConfig } from 'wagmi';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';

export const setupWagmiAndRainbowKit = (
  optimismAlchemyApiKey: string,
  mainnetAlchemyApiKey: string,
) => {
  const { chains, provider } = configureChains(
    [chain.mainnet, chain.optimism],
    [
      alchemyProvider({ apiKey: optimismAlchemyApiKey }),
      alchemyProvider({ apiKey: mainnetAlchemyApiKey }),
      publicProvider(),
    ],
  );

  const connectors = connectorsForWallets([
    {
      groupName: 'Recommended',
      wallets: [
        wallet.metaMask({ chains }),
        wallet.ledger({ chains }),
        wallet.walletConnect({ chains }),
      ],
    },
    {
      groupName: 'Others',
      wallets: [wallet.coinbase({ appName: 'HeyEdu', chains }), wallet.rainbow({ chains })],
    },
  ]);

  const wagmiClient = createClient({
    autoConnect: true,
    connectors,
    provider,
  });

  return {
    wagmiClient,
    supportedChains: chains,
  };
};

export default setupWagmiAndRainbowKit;
