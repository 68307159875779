import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import jwt from 'jwt-decode';
import { AppDispatch, RootState } from '../store';
import { SLS_USER_ENDPOINTS } from '../../constants/apiEndpoints';

import { reset as resetRewards } from './rewardsSlice';
import { reset as resetSkills } from './skilsSlice';
import { reset as resetQuests } from './questsSlice';
import SlsJwtUser, { User } from '../../types/User';
import { getSlsAxiosInstnce } from '../../hooks/useSls';
import { UserGameData, UserMetadata } from '../../types/User';
import Badge from '../../types/Badge';
import { addSpinner, removeSpinner } from './spinnersSlice';

const JWT_STORAGE_KEY = 'jwt';

interface Thunk3rdArg {
  dispatch: AppDispatch;
  state: RootState;
}

type Subset<K> = {
  [attr in keyof K]?: K[attr] extends object ? Subset<K[attr]> : K[attr];
};

type UpdateHero = {
  data: Subset<Omit<User, 'id'>>;
  options: {
    spinners: string[];
  };
};

export const updateHero = createAsyncThunk<User, UpdateHero, Thunk3rdArg>(
  SLS_USER_ENDPOINTS.PATCH_USER('me'),
  async (payload, { dispatch, getState }) => {
    for await (const spinner of payload.options.spinners) {
      await dispatch(addSpinner(spinner));
    }

    const { user } = getState();
    const slsAxios = getSlsAxiosInstnce(dispatch, user.token);
    const response = await slsAxios.patch<{ item: User }>(
      SLS_USER_ENDPOINTS.PATCH_USER(user?.user?.sub as string),
      payload.data,
    );

    for await (const spinner of payload.options.spinners) {
      await dispatch(removeSpinner(spinner));
    }

    return response.data.item;
  },
);

type DiscordData = { id: string | null; username: string | null };

export const updateUserDiscord = createAsyncThunk<DiscordData, DiscordData, Thunk3rdArg>(
  'update/discord',
  async ({ id, username }, { dispatch, getState }) => {
    await dispatch(addSpinner('user_settings.discord'));

    const { user } = getState();
    const slsAxios = getSlsAxiosInstnce(dispatch, user.token);

    if (id === null) {
      await slsAxios.patch(SLS_USER_ENDPOINTS.PATCH_USER_DISCORD(user?.user?.sub as string), {
        discordId: null,
        discordUsername: null,
      });

      await dispatch(removeSpinner('user_settings.discord'));

      return {
        id: null,
        username: null,
      };
    }

    if (username === null) {
      const response = await slsAxios.get<{ discordUsername: string; discordId: string }>(
        SLS_USER_ENDPOINTS.GET_USER_DISCORD(user?.user?.sub as string),
      );

      await slsAxios.patch(SLS_USER_ENDPOINTS.PATCH_USER_DISCORD(user?.user?.sub as string), {
        id,
        username: response.data.discordUsername,
      });

      await dispatch(removeSpinner('user_settings.discord'));

      return {
        id,
        username: response.data.discordUsername,
      };
    }

    await slsAxios.patch(SLS_USER_ENDPOINTS.PATCH_USER_DISCORD(user?.user?.sub as string), {
      discordId: id,
      discordUsername: username,
    });

    await dispatch(removeSpinner('user_settings.discord'));

    return {
      id,
      username,
    };
  },
);

export const logOut = createAsyncThunk<void, void, Thunk3rdArg>(
  'logout',
  async (_, { dispatch, getState }) => {
    dispatch(resetRewards());
    dispatch(resetSkills());
    dispatch(resetQuests());
    dispatch(reset());
  },
);

export const logIn = createAsyncThunk<
  { token: string; user: SlsJwtUser | null },
  string,
  Thunk3rdArg
>('login', async (token, { dispatch, getState }) => {
  const user = jwt(token) as SlsJwtUser;
  localStorage.setItem(JWT_STORAGE_KEY, token);

  dispatch(fetchUser());

  return {
    user,
    token,
  };
});

type FetchUserResponse = {
  gameData: UserGameData;
  badges: Badge[];
  metadata: UserMetadata;
  discord: { id: string | null; username: string | null };
};

export const fetchUser = createAsyncThunk<FetchUserResponse, void, Thunk3rdArg>(
  SLS_USER_ENDPOINTS.GET_USER_HERO('me'),
  async (_, { dispatch, getState }) => {
    const { user } = getState();

    if (!user.hero.isFetched) {
      dispatch(showLoader());
    }

    // TODO
    if (!user?.user) {
      throw new Error('user is empty');
    }

    const slsAxios = getSlsAxiosInstnce(dispatch, user.token);
    const responseUser = await slsAxios.get<{ item: User }>(
      SLS_USER_ENDPOINTS.GET_USER(user.user.sub),
    );

    return {
      gameData: responseUser.data.item.gameData,
      badges: responseUser.data.item.badges,
      metadata: responseUser.data.item.metadata,
      discord: {
        id: responseUser.data.item.discordId,
        username: responseUser.data.item.discordUsername,
      },
    };
  },
);

export interface UserState {
  token: string | null;
  user: SlsJwtUser | null;
  hero: {
    isLoadingHero: boolean;
    isFetched: boolean;
    gameData: UserGameData | null;
    badges: Badge[];
    metadata: UserMetadata | null;
    discord: { id: string | null; username: string | null };
  };
}

const initialState = (): UserState => {
  const token = localStorage.getItem(JWT_STORAGE_KEY);
  let slsUser: SlsJwtUser | null = null;
  if (token) {
    slsUser = jwt(token);
  }

  return {
    token: token,
    user: slsUser,
    hero: {
      isLoadingHero: false,
      isFetched: false,
      gameData: null,
      badges: [],
      metadata: null,
      discord: { id: null, username: null },
    },
  };
};

export const userSlice = createSlice({
  name: 'user',
  initialState: initialState(),
  reducers: {
    reset: (state) => {
      state.token = null;
      state.user = null;
      localStorage.removeItem(JWT_STORAGE_KEY);
    },
    showLoader: (state) => {
      state.hero.isLoadingHero = true;
    },
    hideLoader: (state) => {
      state.hero.isLoadingHero = false;
    },
  },
  extraReducers(builder) {
    builder.addCase(logIn.fulfilled, (state, action) => {
      state.token = action.payload.token;
      state.user = action.payload.user;
    });

    builder.addCase(fetchUser.pending, (state, action) => {
      state.hero.isLoadingHero = true;
    });

    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.hero.isLoadingHero = false;
      state.hero.gameData = action.payload.gameData;
      state.hero.badges = action.payload.badges;
      state.hero.metadata = action.payload.metadata;
      state.hero.discord = action.payload.discord;
      state.hero.isFetched = true;
    });

    builder.addCase(updateHero.fulfilled, (state, action) => {
      state.hero.metadata = action.payload.metadata;
    });

    builder.addCase(updateUserDiscord.fulfilled, (state, action) => {
      state.hero.discord = action.payload;
    });
  },
});

const { reset, showLoader, hideLoader } = userSlice.actions;

export default userSlice.reducer;
