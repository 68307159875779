import { AnimatePresence } from 'framer-motion';
import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { FullScreenLoader } from './components/Loader/Loader';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import { routes } from './constants/routes';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { en } from 'make-plural/plurals';
import { messages } from './locales/en/messages.js';
import useSiwe from './hooks/useSiwe';

i18n.load('en', messages);
i18n.activate('en');
i18n.loadLocaleData('en', { plurals: en });

const PortalHome = lazy(() => import('./pages/PortalHome/PortalHome'));
const Login = lazy(() => import('./pages/Login/Login'));
const AuthDiscord = lazy(() => import('./pages/Auth/Discord'));
const Settings = lazy(() => import('./pages/Settings/Settings'));
const Layout = lazy(() => import('./template/Layout/Layout'));
const Worlds = lazy(() => import('./pages/Worlds/Worlds'));
const WorldDetails = lazy(() => import('./pages/WorldDetails/WorldDetails'));
// const Rewards = lazy(() => import('./pages/Rewards/Rewards'));
// const Quests = lazy(() => import('./pages/Quests/Quests'));
const UserInfoSettings = lazy(() => import('./pages/UserInfoSettings/UserInfoSettings'));
const MyNft = lazy(() => import('./pages/MyNft/MyNft'));
const MyEdu = lazy(() => import('./pages/MyEdu/MyEdu'));
const MyBadges = lazy(() => import('./pages/MyBadges/MyBadges'));
const WorldBadges = lazy(() => import('./pages/MyBadges/components/WorldBadges/WorldBadges'));
// const GenesisNft = lazy(() => import('./pages/GenesisNft/GenesisNft'));
const FoundersNft = lazy(() => import('./pages/FoundersNft/FoundersNft'));
const WorldsLayout = lazy(() => import('./template/WorldsLayout/WorldsLayout'));
const WorldLayout = lazy(() => import('./template/WorldLayout/WorldLayout'));
const WorldMap = lazy(() => import('./pages/WorldMap/WorldMap'));

const App = () => {
  const { loading } = useSiwe();

  return (
    <I18nProvider i18n={i18n}>
      <Suspense fallback={<FullScreenLoader />}>
        <AnimatePresence exitBeforeEnter>
          <Routes>
            <Route path={routes.login} element={<Login loading={loading} />} />
            <Route path={routes.portal.self} element={<PrivateRoute Component={Layout} />}>
              <Route path={routes.portal.auth.discord} element={<AuthDiscord />} />
              <Route index element={<PortalHome />} />

              <Route path={routes.portal.settings.self} element={<Settings />}>
                <Route path={routes.portal.settings.userDetails} element={<UserInfoSettings />} />
              </Route>
              <Route path={routes.portal.myEdu} element={<MyEdu />} />
              <Route path={routes.portal.myBadges.self} element={<MyBadges />}>
                <Route path={routes.portal.myBadges.worldBadges} element={<WorldBadges />} />
              </Route>
              <Route path={routes.portal.myNft.self} element={<MyNft />}>
                {/*<Route path={routes.portal.myNft.genesis} element={<GenesisNft />} />*/}
                <Route path={routes.portal.myNft.founders} element={<FoundersNft />} />
              </Route>
              <Route path={routes.portal.worlds.self} element={<WorldsLayout />}>
                <Route path={routes.portal.worlds.self} element={<Worlds />} />
                <Route path={routes.portal.worlds.world.self} element={<WorldLayout />}>
                  <Route path={routes.portal.worlds.world.self} element={<WorldMap />} />
                  <Route path={routes.portal.worlds.world.details} element={<WorldDetails />} />
                </Route>
              </Route>
              {/* <Route path={routes.portal.rewards} element={<Rewards />} /> */}
              {/* <Route path={routes.portal.quests} element={<Quests />} /> */}
            </Route>
            <Route path="*" element={<Navigate replace to={routes.portal.worlds.self} />} />
          </Routes>
        </AnimatePresence>
      </Suspense>
    </I18nProvider>
  );
};

export default App;
