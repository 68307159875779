import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import '@rainbow-me/rainbowkit/styles.css';
import './index.css';
import App from './App';
import { MAINNET_ALCHEMY_API_KEY, OPTIMISM_ALCHEMY_API_KEY } from './constants/apiEndpoints';
import { store } from './redux/store';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle, theme } from './style';
import React from 'react';

import { setupWagmiAndRainbowKit } from './onChain/setup';
import { chain, WagmiConfig } from 'wagmi';
import { RainbowKitProvider, darkTheme } from '@rainbow-me/rainbowkit';

const { wagmiClient, supportedChains } = setupWagmiAndRainbowKit(
  OPTIMISM_ALCHEMY_API_KEY,
  MAINNET_ALCHEMY_API_KEY,
);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <WagmiConfig client={wagmiClient}>
            <RainbowKitProvider
              chains={supportedChains}
              initialChain={chain.optimism}
              theme={darkTheme()}
            >
              <App />
            </RainbowKitProvider>
          </WagmiConfig>
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
