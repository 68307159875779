export const MAINNET_ALCHEMY_API_KEY = 'umnx9mAEzPgs3UW4jyDOWDnPVkw2u8RN';
export const MAINNET_ALCHEMY_API_URL = `https://eth-mainnet.g.alchemy.com/v2/${MAINNET_ALCHEMY_API_KEY}`;

export const OPTIMISM_ALCHEMY_API_KEY = 'MdoKuQrqPMOe4FCDMh90OBKSL1AdsdBm';
export const OPTIMISM_ALCHEMY_APU_URL = `https://opt-mainnet.g.alchemy.com/v2/${OPTIMISM_ALCHEMY_API_KEY}`;

const API_URI = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3000/';
const API_VERSION = process.env.REACT_APP_BACKEND_VERSION || 'v1';

const SLS_API_URI =
  process.env.REACT_APP_SLS_BACKEND_URL ||
  'https://ct58cxom81.execute-api.eu-west-1.amazonaws.com/dev/';

export const SLS_API_URL = `${SLS_API_URI}`;
export const SLS_USER_ENDPOINTS = {
  GET_USERS: (lastId?: string, email?: string, ethWallet?: string) =>
    `users?lastId=${lastId ?? ''}&email=${email ?? ''}&ethWallet=${ethWallet ?? ''}`,
  GET_USER: (id: string | number) => `user/${id}`,
  POST_USER: 'user',
  PUT_USER: (id: string) => `user/${id}`,
  PATCH_USER: (id: string) => `user/${id}`,
  PATCH_USER_DISCORD: (id: string) => `user/${id}/discord`,
  GET_USER_DISCORD: (id: string) => `user/${id}/discord`,
  GET_HONEYPOT_USERS: 'get-honeypot-users',
  GET_BADGES: 'user/badges',
  GET_QUESTS: 'user/quests',
  GET_REWARDS: 'user/rewards',
  GET_USER_HERO: (id: string) => `user-hero/${id}`,
  GET_WORLDS: `user/worlds`,
  GET_PATHS: (worldName: string) => `user/path/${worldName}`,
  GET_EDU_BALANCE: `user/edu-balance`,
};

export const API_URL = `${API_URI}${API_VERSION}/`;

export const LOGIN = `${SLS_API_URL}login`; //this is full URL as its not using useAxios hook.
export const QUESTS = {
  CHECKQUEST: (textId: string) => `quests/check-quest/${textId}`,
  ALLMINE: 'quests/all-mine',
};

export const USERS = {
  PATCHME: 'users/me',
  GETIDBYWALLETID: (ethWallet: string) => `users/${ethWallet}`,
  GET_HERO: 'user/hero',
};

export const BADGES = {
  MINE: 'badges/mine',
};

export const REWARDS = {
  MINE: 'rewards/mine',
  CLAIMREWARD: `rewards/claim-rewards`,
  REWARDCLAIMED: `rewards-claimed`,
};

export const WEB3 = {
  GETSIGNATURE: 'web3/get-signature',
};
