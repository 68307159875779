import { LazyExoticComponent, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { routes } from '../../constants/routes';
import { useUser } from '../../hooks/useUser';

type ComponentType = {
  Component: LazyExoticComponent<() => JSX.Element>;
};

const PrivateRoute = ({ Component }: ComponentType) => {
  const { user, account } = useUser();

  return account && user?.walletAddress ? <Component /> : <Navigate to={routes.login} />;
};

export default PrivateRoute;
