import { useSelector } from 'react-redux';
import { useAccount } from 'wagmi';
import { selectUser } from '../redux/selectors/userSelector';

export function useUser() {
  const user = useSelector(selectUser);
  const { address: account } = useAccount();

  return { user, account };
}
