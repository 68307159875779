import axios, { AxiosInstance } from 'axios';
import { SLS_API_URL, SLS_USER_ENDPOINTS, WEB3 } from '../constants/apiEndpoints';
import { setNewJWTToken } from '../constants/customHttpHeaders';
import { logIn } from '../redux/slices/userSlice';
import { Dispatch } from 'react';
import { AnyAction } from '@reduxjs/toolkit';
import { selectToken } from '../redux/selectors/userSelector';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../redux/store';
import { User } from '../types/User';
import { UserGameData } from '../types/User';
import { World } from '../types/World';
import { Path } from '../types/Path';
import { PathLevel } from '../types/PathLevel';
import { BigNumber } from 'ethers';

export function getSlsAxiosInstnce(
  dispatch: Dispatch<AnyAction>,
  token: string | null,
): AxiosInstance {
  const instance = axios.create({
    baseURL: `${SLS_API_URL}`,
  });

  instance.interceptors.request.use(
    async (config) => {
      if (!token) {
        throw new axios.Cancel('Token missing');
      }
      config.headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
      return config;
    },
    (error) => {
      Promise.reject(error);
    },
  );

  instance.interceptors.response.use(
    async (response) => {
      if (response.headers[setNewJWTToken]) {
        const newToken = response.headers[setNewJWTToken];
        //@ts-ignore
        await dispatch(logIn(newToken));
      }
      return response;
    },
    async (error) => {
      // if (error.response.status === 401) {
      //   //@ts-ignore
      //   await dispatch(logOut());
      //   throw new axios.Cancel('Unauthorized');
      // }
    },
  );
  return instance;
}

function useSlsAxios() {
  const dispatch = useDispatch<AppDispatch>();
  const token = useSelector(selectToken);
  return getSlsAxiosInstnce(dispatch, token);
}

function useSls(axiosInstance?: AxiosInstance) {
  const slsAxiosInstance = useSlsAxios();
  const slsAxios = axiosInstance ?? slsAxiosInstance;

  const getUser = async ({ id }: { id: string | number }) => {
    if (!id) {
      throw new Error('Id param missing');
    }

    const response = await slsAxios.get<{ item: User }>(SLS_USER_ENDPOINTS.GET_USER(id));
    return response.data.item;
  };

  const updateUser = async ({ id, slsUser }: { id: string; slsUser: User }): Promise<User> => {
    const response = await slsAxios.put<{ user: User }>(SLS_USER_ENDPOINTS.PUT_USER(id), slsUser);
    return response.data.user;
  };

  interface getUsersInput {
    lastId?: string;
    email?: string;
    ethWallet?: string;
  }
  const getUsers = async ({ lastId, email, ethWallet }: getUsersInput) => {
    const usersResponse = await slsAxios.get<{ items: any[]; lastId: string }>(
      SLS_USER_ENDPOINTS.GET_USERS(lastId, email, ethWallet),
    );
    return usersResponse.data;
  };

  const addUser = async ({ ethWallet, email }: { ethWallet: string; email: string }) => {
    return await slsAxios.post(SLS_USER_ENDPOINTS.POST_USER, {
      ethWallet,
      email,
    });
  };

  const getHoneypotUsers = async () => {
    const response = await slsAxios.get(SLS_USER_ENDPOINTS.GET_HONEYPOT_USERS);
    return response.data.items;
  };

  const getUserHero = async ({ id }: { id: string }): Promise<UserGameData> => {
    if (!id) {
      throw new Error('Id param missing');
    }

    const response = await slsAxios.get<{ item: { gameData: UserGameData } }>(
      SLS_USER_ENDPOINTS.GET_USER_HERO(id),
    );
    return response?.data?.item?.gameData;
  };

  const getUserBadges = async () => {
    const response = await slsAxios.get(SLS_USER_ENDPOINTS.GET_BADGES);
    return response?.data;
  };

  const getWorlds = async (): Promise<{ worlds: World[] }> => {
    const response = await slsAxios.get<{ worlds: World[] }>(SLS_USER_ENDPOINTS.GET_WORLDS);

    return response?.data;
  };

  const getPaths = async (worldName: string): Promise<GetPathsPathModel[]> => {
    const response = await slsAxios.get<{ paths: GetPathsPathModel[] }>(
      SLS_USER_ENDPOINTS.GET_PATHS(worldName),
    );

    return response?.data.paths;
  };

  const getSignature = async () => {
    const response = await slsAxios.post<{ signature: string; eduEarned: string }>(
      WEB3.GETSIGNATURE,
    );

    return response?.data;
  };

  const getEduBalance = async () => {
    const response = await slsAxios.get<{ eduBalance: BigNumber }>(
      SLS_USER_ENDPOINTS.GET_EDU_BALANCE,
    );
    return response.data;
  };

  return {
    getUser,
    getUsers,
    updateUser,
    addUser,
    getHoneypotUsers,
    getUserHero,
    getUserBadges,
    getWorlds,
    getPaths,
    getEduBalance,
    getSignature,
  };
}

export default useSls;

export type GetPathsPathLevelModel = PathLevel & {
  statusForUser: 'active' | 'done' | 'available' | 'unavailable';
};
export type GetPathsPathModel = Path & {
  statusForUser: 'active' | 'available' | 'unavailable';
  pathProgress: number;
  pathLevels: GetPathsPathLevelModel[];
  badges: {
    id: string;
    url: string;
  }[];
};
