import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { routes } from '../constants/routes';
import axios from 'axios';
import useSiweAuth from '../onChain/hooks/useSiweAuth';
import { logIn, logOut } from '../redux/slices/userSlice';
import { AppDispatch } from '../redux/store';
import { LOGIN } from '../constants/apiEndpoints';
import { useState } from 'react';
import { selectUser } from '../redux/selectors/userSelector';
import SlsJwtUser from '../types/User';

function checkIfJWTExpired(user: SlsJwtUser | null): boolean | PromiseLike<boolean> {
  if (!user) {
    return false;
  }

  return Date.now() >= user.exp * 1000;
}

function useSiwe() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);
  const user = useSelector(selectUser);

  const authenticatedAddress = useSiweAuth({
    origin: window.location.origin,
    domain: window.location.host,
    shouldResignSiweOnReconnect: async () => {
      const hasJWTExpired = checkIfJWTExpired(user);

      return hasJWTExpired;
    },
    onSuccessfulSign: async (signature: string, message: string, isReconnected: boolean) => {
      setLoading(true);
      let correctSign = false;

      if (!isReconnected) {
        try {
          const response = await axios.post(LOGIN, { message, signature });
          await dispatch(logIn(response.data.access_token));

          correctSign = true;
        } catch (e) {
          console.log(e);
          correctSign = false;
        }
      } else {
        correctSign = true;
      }

      setLoading(false);

      if (correctSign) {
        navigate(routes.portal.worlds.self);
      }
    },
    onDisconnect: async () => {
      console.log('Client disconnected');
      await dispatch(logOut());
      navigate(routes.login);
    },
    onAccountChange: async () => {},
    onNotSuccessfulSignBeforeDisconnect: () => {
      // TODO: What to do after user cancels SIWE
      console.log('Client canceled SIWE');
    },
  });

  return {
    authenticatedAddress,
    loading,
  };
}

export default useSiwe;
