import { RootState } from '../store';

export const selectToken = ({ user }: RootState) => user.token;

export const selectIsUserLoggedIn = ({ user }: RootState) => {
  return !!user.user?.walletAddress;
};

export const selectUser = ({ user }: RootState) => user.user;

export const selectUserGamedata = ({ user }: RootState) => user.hero.gameData;
export const selectUserIsLoadingHero = ({ user }: RootState) => user.hero.isLoadingHero;
export const selectUserBadges = ({ user }: RootState) => user.hero.badges;
export const selectUserMetadata = ({ user }: RootState) => user.hero.metadata;
export const selectUserDiscord = ({ user }: RootState) => user.hero.discord;
